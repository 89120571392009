import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { GolfCoursesPage } from '../imageURLs';

import SEO from '../components/SEO';

import Hero from '../components/Hero/Hero';
import TextBlock from '../components/TextBlock';
import CtaCard from '../components/CtaCard/CtaCard';

import { withQuoteWizard } from '../components/withQuoteWizard';
import CTAButton from '../components/CTAButton/CTAButton';
import CtaBlock from '../components/CtaBlock';
import ServiceIntroBlock from '../components/ServiceIntroBlock';
import CenterContent from '../components/CenterContent';

const styles = theme =>
  createStyles({
    cards: {
      backgroundColor: '#FFFFFF',
    },
  });

class ThePage extends React.Component {
  render() {
    const { children, classes } = this.props;

    return (
      <React.Fragment>
        <SEO
          title="Golf Course Projects by Twin View Turf - Producer of Australia’s Best Lawns"
          keywords={[
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Hero heroImage={GolfCoursesPage.hero} noQuote>
          <TextBlock text="Golf course services" />
        </Hero>

        <Grid container item className={classes.cards}>
          <ServiceIntroBlock
            checklist={['Latest cultivars', '360 management', 'No downtime']}
            statementTitle="Golf Course Specialists"
            statementCopy="Twin View Turf are SEQ's most reputable golf course specialist. Our experience, product knowledge and specialist grass varieties allow us to provide comprehensive golf course turf services."
          />
        </Grid>

        <Grid container item className={classes.cards}>
          <CenterContent style={{ maxWidth: 960 }}>
            <CtaCard
              image={GolfCoursesPage.article1}
              header="RACV Royal Pines"
              text="A Graham Marsh redeveloped Championship Course featuring 27 holes of world-class golf."
              ctaHref="/golf-courses/racv-royal-pines"
              ctaText="Read more"
            />
            <CtaCard
              image={GolfCoursesPage.article2}
              header="Maroochy River Golf Club"
              text="A Graham Marsh designed Championship Course featuring 18 holes of world-class golf."
              ctaHref="/golf-courses/maroochy-river-golf-club"
              ctaText="Read more"
            />
          </CenterContent>
        </Grid>

        <CtaBlock title="Considering a course upgrade?">
          <CTAButton
            inverted
            ctaHref="/contact"
            ctaText="Contact Us"
          />
        </CtaBlock>

        {children}
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage)
);
